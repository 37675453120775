import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillLinkedin,
} from "react-icons/ai";

const Footer = ({ tel }) => {
  const telNum = tel || "1273 465 336";
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { absolutePath: { regex: "/images/payment-logos/" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  return (
    <Container fluid={true} className="bg-black text-offwhite pt-10 pb-10">
      <Container>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={12} className="d-flex justify-content-center">
                <Link to="/">
                  <StaticImage
                    src="../images/logo.png"
                    style={{ width: "160px" }}
                    placeholder="tracedSVG"
                    alt="Seymour Locksmiths Logo"
                  />
                </Link>
              </Col>

              <Row className="pt-10">
                <Col
                  xs={12}
                  lg={6}
                  className="d-flex justify-content-center justify-content-sm-start"
                >
                  <a
                    className="d-block text-offwhite text-md hover-no-decoration"
                    href={`tel:+${telNum}`}
                  >
                    0{telNum}
                  </a>
                </Col>
                <Col
                  xs={12}
                  lg={6}
                  className="d-flex justify-content-center justify-content-sm-end"
                >
                  <a
                    className="d-block text-offwhite text-md hover-no-decoration"
                    href="mailto:info@seymour-locksmiths.co.uk"
                  >
                    info@seymour-locksmiths.co.uk
                  </a>
                </Col>
              </Row>

              <Col xs={12} className="d-flex justify-content-center pt-10 mb-4">
                <a
                  href="https://g.page/seymour-locksmiths?share"
                  target="_blank"
                  rel="noreferrer"
                  className="text-center text-offwhite text-sm-start mb-4 hover-no-decoration"
                >
                  Jeffrey Seymour t/a Seymour Locksmiths Basepoint Business
                  Centre Little High Street Shoreham-by-Sea BN43 5EG
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
