import React from "react";
import { BsCheckCircle } from "react-icons/bs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SlButton from "../components/button";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import PropTypes from "prop-types";
import uuid4 from "uuid4";
import ContactForm from "./contactForm";
import { StaticImage } from "gatsby-plugin-image";
import ContactFormAd from "./contact-form-ad";

const Hero = (props) => {
	const pluginImage = getImage(props.image);
	const headingFontSize = props.hFontSize || "text-3xl";

	return (
		<BgImage image={pluginImage} style={{ minHeight: "100vh" }}>
			<Container className="py-2">
				<Row className="vh-100 justify-content-between align-items-center align-content-center landing__hero">
					<Col
						className={`text-offwhite text-center ${
							props.textCenter ? "" : "text-md-start"
						}`}
						xs={12}
						lg={7}
					>
						<h1
							className={`lato fw-700 ${headingFontSize} title-container-spacing`}
						>
							{props.heading}
						</h1>
						<p className="text-lg title-container-spacing">{props.text}</p>
						<div className={`${props.checksHidden && "d-none"}`}>
							{props.actions && props.actions.length > 0 && (
								<Row className="title-container-spacing text-md home-checklist text-white">
									<Col className="d-md-flex  ">
										{props.actions.map((item) => (
											<span
												className="pe-3 lato fw-700  d-flex align-items-center"
												key={uuid4()}
											>
												<BsCheckCircle className="text-orange text-lg me-2" />
												{item.heading}
											</span>
										))}
									</Col>
								</Row>
							)}
							{props.button && (
								<>
									<SlButton
										size="small"
										className={`text-lg emergency-line-button d-none d-md-inline-block `}
										variant="blue"
										as="a"
										href={props.button.url}
									>
										{props.button.title}
									</SlButton>
									<SlButton
										size="small"
										className={`text-lg emergency-line-button w-100 d-md-none `}
										variant="blue"
										as="a"
										href={props.button.url}
									>
										{props.button.title}
									</SlButton>
								</>
							)}
							{props.button2 && (
								<>
									<SlButton
										size="small"
										variant="outline-blue"
										className={` d-none d-md-inline-block text-lg `}
										as="a"
										href={props.button2.url}
									>
										{props.button2.title}
									</SlButton>
									<SlButton
										size="small"
										variant="outline-blue"
										className={`w-100 d-md-none  text-lg }`}
										as="a"
										href={props.button2.url}
									>
										{props.button2.title}
									</SlButton>
								</>
							)}
						</div>
						<div className="d-flex align-items-center mt-3">
							<StaticImage
								quality="100"
								src="../images/5stars.svg"
								alt="google"
								placeholder="blurred"
								width={100}
								formats={["auto", "webp"]}
								className="me-2 ms-2"
							/>
							<p className="fw-semibold fs-6 py-0 my-0 me-2">
								<span className="fw-bold" style={{ color: "#FEA500" }}>
									5.0
								</span>
							</p>

							<p className="fw-semibold fs-6 py-0 my-0">
								<span className="me-2">|</span>
								{props.reviewsTotal} Google reviews
							</p>
						</div>
					</Col>
					<Col lg={4} className="my-4 mt-sm-0">
						<ContactFormAd
							adPage={props.adPage}
							btnText={"Send Message"}
							isBackground={true}
						/>
					</Col>
				</Row>
			</Container>
			<span className="hero-overlay" />
		</BgImage>
	);
};

Hero.propTypes = {
	image: PropTypes.object,
	checksHidden: PropTypes.bool,
	callBHidden: PropTypes.bool,
	scrollBHidden: PropTypes.bool,
	callBackVisible: PropTypes.bool,
	textCenter: PropTypes.bool,
	handleClick: PropTypes.func,
};

export default Hero;
